<template>
  <AView
    :value="value"
    :actions="actions"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onGlossaryTermSave="onCreate"
  >
    <template v-slot:content="{}">
      <NewGlossaryTermForm
        ref="form"
        class="pa-4"
        v-model="form"
        :permissions="value.permissions"
        @action="(action) => action.handler({ from: value })"
      ></NewGlossaryTermForm>
    </template>
  </AView>
</template>
  
  <script>
import { PermissionsHelper } from "../../../../../../components/helpers/permissions.helper";
import NewGlossaryTermForm from "../../../../../../components/wad/organisms/glossary/forms/NewGlossaryTermForm.vue";
import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";
import { GLOSSARY_BUTTONS } from "../../constants/actions.gh";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    NewGlossaryTermForm,
    AView,
  },
  data: () => ({
    form: {},
  }),
  created() {},
  computed: {
    actions: function () {
      return PermissionsHelper.getActions(
        GLOSSARY_BUTTONS.SAVE,
        this.value.permissions
      );
    },
  },

  methods: {
    onFocus() {},
    onCreate() {
      const valid = this.$refs.form.validate();
      if (valid)
        this.$store.dispatch("GlossaryStore/CreateNewTerm", {
          from: this.value,
          newTerm: this.form,
        });
    },
  },
};
</script>